import React from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./assets/scss/app.scss";

import HeaderComponent from "./components/header";
import SlideComponent from "./components/slide";
import CourseComponent from "./components/course";
import BenefitsComponent from "./components/benefits";
import AboutComponent from "./components/about";
import ContactComponent from "./components/contact";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <HeaderComponent></HeaderComponent>
          <SlideComponent lang="en"></SlideComponent>
          <CourseComponent lang="en"></CourseComponent>
          <BenefitsComponent lang="en"></BenefitsComponent>
          <AboutComponent lang="en"></AboutComponent>
          <ContactComponent lang="en"></ContactComponent>
        </Route>
        <Route path="/es">
          <Helmet>
            <title>Estudia un MBA y trabaja en Malta</title>
            <meta name="title" content="Estudia un MBA y trabaja en Malta" />
            <meta
              name="description"
              content="Estudia en Europa y proyecta tu carrera internacionalmente. Conoce más acerca de nuestro MBA británico en Malta y la posibilidad de trabajar hasta 20 horas por semana."
            />
            <meta
              name="keywords"
              content="MBA en Malta, Estudiar en Malta, Master of Business Administration en Malta, estudiar y trabajar en Malta, estudia ingles en Malta, curso de ingles"
            />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="Spanish" />
          </Helmet>
          <HeaderComponent></HeaderComponent>
          <SlideComponent lang="es"></SlideComponent>
          <CourseComponent lang="es"></CourseComponent>
          <BenefitsComponent lang="es"></BenefitsComponent>
          <AboutComponent lang="es"></AboutComponent>
          <ContactComponent lang="es"></ContactComponent>
        </Route>
        <Route path="/ru" exact>
          <HeaderComponent></HeaderComponent>
          <SlideComponent lang="ru"></SlideComponent>
          <CourseComponent lang="ru"></CourseComponent>
          <BenefitsComponent lang="ru"></BenefitsComponent>
          <AboutComponent lang="ru"></AboutComponent>
          <ContactComponent lang="ru"></ContactComponent>
        </Route>
      </Switch>
      <div style={{ visibility: "hidden" }}>
        <Link to="/">en</Link>
        <Link to="/es">es</Link>
        <Link to="/ru">ru</Link>
      </div>
    </BrowserRouter>
  );
}

export default App;
