import BenefitsImg from "../assets/img/reasons-to-study-at-lsc-malta.jpg";

const benefits = ({ lang }: { lang: string }) => {
  const Content = () => {
    if (lang === "en") {
      return (
        <article>
          <h1>Reasons to student at LSC Malta</h1>
          <span></span>
          <ul>
            <li>Globally recognised college</li>
            <li>Double award: British MBA and European Postgraduate</li>
            <li>Graduation ceremony in the UK (optional)</li>
            <li>Flexible intakes and every 2 months (approx)</li>
            <li>Virtual library with 70.000+ academic materials</li>
            <li>Modern and central campus in paradisical country</li>
            <li>Leading faculty from different business industries</li>
            <li>Assistance on the travel planning</li>
            <li>Assistance and documents review for the visa application</li>
            <li>Affordable tuition fees</li>
          </ul>
        </article>
      );
    } else if (lang === "es") {
      return (
        <article>
          <h1>RAZONES PARA ESTUDIAR EN LSC MALTA</h1>
          <span></span>
          <ul>
            <li>College de negocios con reconocimiento internacional</li>
            <li>Doble titulación: MBA británico y Posgrado Europeo</li>
            <li>Inicios de clases flexibles y varias veces por año</li>
            <li>Posibilidad de trabajar hasta 20 horas por semana</li>
            <li>Acompañamiento en la planeación del viaje</li>
            <li>Asesoría y revisión de documentos para el visado</li>
            <li>Campus moderno y central en un país paradisíaco</li>
            <li>Biblioteca virtual con más 70.000 materiales académicos</li>
            <li>Profesores expertos en diferentes áreas de negocio</li>
            <li>Ceremonia de grado en el Reino Unido (opcional)</li>
            <li>Extiende tu estadía hasta 9 meses después de finalizar el MBA, con fines de búsqueda de trabajo en Malta.</li>
            <li>Recibe hasta el 70% del valor del programa a través del programa Get Qualified, si te quedas trabajando en Malta.</li>
          </ul>
        </article>
      );
    } else if (lang === "ru") {
      return (
        <article>
          <h1>ПРИЧИНЫ ОБУЧАТЬСЯ В LSC MALTA</h1>
          <span></span>
          <ul>
            <li>Высшее учебное заведение, признанное во всем мире </li>
            <li>Обучение на Мальте</li>
            <li>Двойной диплом: диплом британского университета и европейская магистратура</li>
            <li>Выпускной в Великобритании (по желанию) </li>
            <li>Гибкий прием и каждые 2 месяца (приблизительно) </li>
            <li>Виртуальная библиотека с более чем 70 000 академических материалов </li>
            <li>Современный центральный кампус в райской стране </li>
            <li>Ведущие преподаватели из различных сфер бизнеса </li>
            <li>Помощь в планировании поездки </li>
            <li>Помощь и рассмотрение документов для подачи заявления на визу </li>
            <li>Доступная плата за обучение</li>
          </ul>
        </article>
      );
    }
  };

  return (
    <section id="benefits">
      <div>
        <img src={BenefitsImg} alt="Reasons to student at LSC Malta"></img>
      </div>
      <div>{Content()}</div>
    </section>
  );
};

export default benefits;
