import { useEffect, useState } from "react";
import FormComponent from "./form";

const Slide = ({ lang }: { lang: string }) => {
  const [Content, setContent] = useState({
    h1: "",
    p: "",
    a: "",
    h12: "",
    p2: "",
  });

  useEffect(() => {
    if (lang === "en") {
      setContent({
        h1: "London School of Commerce Malta",
        p:
          "London School of Commerce, Malta Ltd (LSCM) was established in June 2014 as a global higher education provider in Malta and forms part of the London School of Commerce Global Group.",
        a: "Prospectus",
        h12: "Apply now",
        p2: "Enter your details below we will contact you soon",
      });
    } else if (lang === "es") {
      setContent({
        h1: "Estudia un MBA en Malta",
        p:
          "¡Continúa tus estudios en Europa! Estudia una Maestría en Negocios (doble titulación) y proyecta tu carrera internacionalmente. Aplica antes del 31 de marzo y postula una beca parcial en el costo total del programa. *Si no sabes inglés, también ofrecemos cursos de inglés de acuerdo con tu nivel.",
        a: "Descarga la presentación del programa",
        h12: "Solicita más información",
        p2: "Ingresa tus datos de contacto.",
      });
    } else if (lang === "ru") {
      setContent({
        h1: "London School of Commerce, Malta",
        p:
          "London School of Commerce, Malta Ltd (LSCM) была основана в июне 2014 года как глобальный провайдер высшего образования на Мальте и является частью London School of Commerce Global Group.",
        a: "Подробнее",
        h12: "Узнайте подробности",
        p2: "Заполните форму и мы свяжемся с вами в ближайшее время",
      });
    }
  }, [lang]);

  return (
    <section id="slide">
      <div className="backdrop">
        <div className="content">
          <article>
            <h1>{Content.h1}</h1>
            <span></span>
            <p>{Content.p}</p>
            <a href="https://www.lscmalta.edu.mt/resources/downloads/lscmalta/prospectus.pdf" rel="noreferrer" target="_blank">
              {Content.a}
            </a>
          </article>
        </div>
        <div className="form">
          <article>
            <h1>{Content.h12}</h1>
            <p>{Content.p2}</p>
            <FormComponent lang={lang}></FormComponent>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Slide;
