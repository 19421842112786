import { useEffect, useState } from "react";
import AboutLSCMalta from "../assets/img/about-lsc-malta.jpg";

const About = ({ lang }: { lang: string }) => {
  const [Con, setCon] = useState({
    t: "",
    g: "",
    y: "",
  });

  useEffect(() => {
    if (lang === "en") {
      setCon({ t: "Testimonies", g: "Gallery", y: "Wcp94v9j5MA" });
    } else if (lang === "es") {
      setCon({ t: "Testimonies", g: "Galería", y: "Wcp94v9j5MA" });
    } else if (lang === "ru") {
      setCon({ t: "Отзывы", g: "Галерея", y: "Wcp94v9j5MA" });
    }
  }, [lang]);

  const Content = () => {
    if (lang === "en") {
      return (
        <article>
          <h1>About Us</h1>
          <span></span>
          <p>
            London School of Commerce, Malta Ltd (LSCM) was established in June 2014 as a global higher education provider in Malta and forms part of
            the London School of Commerce Global Group.
            <br />
            <br />
            The primary objective of LSCM is to be a leading global higher education provider in Malta. In keeping with the vision and mission of its
            parent institution, namely the London School of Commerce (LSC), London, the LSCM aims to provide high quality and innovative undergraduate
            and postgraduate degree programmes to prospective students and professionals in Malta as well as to those from other countries. This
            vision parallels the policy of the Government of Malta particularly in its objective of promoting Malta as a Higher Education Hub to
            attract global talent and engage with regional business to create a sustainable economy.
          </p>
        </article>
      );
    } else if (lang === "es") {
      return (
        <article>
          <h1>Acerca del London School of Commerce Malta</h1>
          <span></span>
          <p>
            LSC es un college internacional de negocios con presencia en 6 países alrededor del mundo, incluyendo Malta. Nuestro campus se encuentra
            ubicado a la entrada de La Valeta, la capital del país y a tan solo unos metros de la estación principal de transporte.
            <br />
            <br />
            Contamos con salones de clase equipados con aire acondicionado, video proyectores y equipos de sonido. Una biblioteca, una sala de
            cómputo, un área social y un área de snacks. Nuestros estudiantes tienen acceso a wifi gratuito en todo el campus, máquinas dispensadoras
            de comida y fuentes de agua potable.
          </p>
        </article>
      );
    } else if (lang === "ru") {
      return (
        <article>
          <h1>О нас</h1>
          <span></span>
          <p>
            Основная цель LSCM - стать ведущим мировым поставщиком высшего образования на Мальте. В соответствии с видением и миссией своего головного
            учреждения, а именно London School of Commerce (LSC), London, LSCM стремится предоставлять высококачественные и инновационные программы
            бакалавриата и магистратуры для перспективных студентов и профессионалов на Мальте, а также тем из других стран. Это видение перекликается
            с политикой правительства Мальты, в частности, с его целью продвигать Мальту как центр высшего образования для привлечения глобальных
            талантов и взаимодействия с региональными предприятиями для создания устойчивой экономики.
          </p>
        </article>
      );
    }
  };

  return (
    <section id="about">
      <div className="about">{Content()}</div>
      <div className="links">
        <div>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${Con.y}?controls=0`}
            title="Testimonies"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div>
          <a href={`https://www.youtube.com/embed/${Con.y}?controls=0`} target="_blank" rel="noreferrer">
            {Con.t}
          </a>
        </div>
        <div>
          <a href="https://www.lscconnect.co.uk/" target="_blank" rel="noreferrer">
            {Con.g}
          </a>
        </div>
        <div>
          <img src={AboutLSCMalta} alt="About London School of Commerce Malta"></img>
        </div>
      </div>
    </section>
  );
};

export default About;
