const course = ({ lang }: { lang: string }) => {
  const About = () => {
    if (lang === "en") {
      return (
        <article>
          <h1>About the course</h1>
          <span></span>
          <p>
            The Master of Business Administration (MBA) attracts people from a wide range of academic disciplines.
            <br />
            <br />
            The core modules of the MBA programme are designed to introduce students to various areas of business including Accounting, Finance,
            Marketing, Human resource management and Strategic Management. Students on the MBA programme also have a choice of specialist electives,
            prior to proceeding to the Major project.
            <br />
            <br />
            This programme of study leads to an MBA degree awarded by a British University. This internationally recognised programme is delivered at
            UK Level 7 which also equates to European Qualifications Framework Level 7 standard
          </p>
          <table>
            <tbody>
              <tr>
                <td>Duration</td>
                <td>12 Months</td>
              </tr>
              <tr>
                <td>Course Fee</td>
                <td>&euro; 6950</td>
              </tr>
              <tr>
                <td>Awarding body</td>
                <td>University of Suffolk</td>
              </tr>
            </tbody>
          </table>
        </article>
      );
    } else if (lang === "es") {
      return (
        <article>
          <h1>Acerca del programa</h1>
          <span></span>
          <p>
            El MBA está dirigido a jóvenes profesionales de diferentes países y campos del conocimiento que buscan proyectar su carrera
            internacionalmente y en el mundo de los negocios.
            <br />
            <br />
            El programa está diseñado para introducirlos en diferentes áreas de estudio como Análisis Financiero, Emprendimiento, Gestión Marketing,
            Gestión de Recursos Humanos, Liderazgo y Gerencia Estratégica. Del mismo modo, los estudiantes tendrán la oportunidad de escoger dos
            asignaturas electivas para especializarse en un área específica de negocio. Una vez se cursen satisfactoriamente todos los módulos del
            MBA, se deberá presentar el Proyecto Final de maestría, este puede ser el desarrollo de una Propuesta de Negocio, un Estudio de Caso o una
            Disertación.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Duración</td>
                <td>12 meses</td>
              </tr>
              <tr>
                <td>Valor total</td>
                <td>&euro; 6950 (Becas parciales disponibles)</td>
              </tr>
              <tr>
                <td>Awarding body</td>
                <td>University of Suffolk</td>
              </tr>
            </tbody>
          </table>
        </article>
      );
    } else if (lang === "ru") {
      return (
        <article>
          <h1>О курсе</h1>
          <span></span>
          <p>
            Магистратура по направлению Master of Business Administration (MBA) подойдет для выпускников бакалавриата различного вида специальностей.
            <br />
            <br />
            Ключевые модули программы созданы чтобы рассказать студентам о различных сторонах бизнеса, включая: бухгалтерию, финансы, управление
            человеческими ресурсами (HR) и стратегический менеджмент. Студенты программы MBA также могут выбрать на свое усмотрение модули
            специализации, перед защитой своего дипломного проекта.
            <br />
            <br />
            Эта магистерская программа предоставляет возможность получить степень MBA в одном из университетов Великобритании.
            <br />
            <br />
            Эта всемирно известная программа соответствует европейскому стандарту качества уровня 7.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Продолжительность</td>
                <td>12 месяцев</td>
              </tr>
              <tr>
                <td>Стоимость обучения</td>
                <td>6950 евро</td>
              </tr>
              <tr>
                <td>Диплом выдает</td>
                <td>University of Suffolk</td>
              </tr>
            </tbody>
          </table>
        </article>
      );
    }
  };

  const Module = () => {
    if (lang === "en") {
      return (
        <article>
          <h1>Course Modules</h1>
          <h4>Core Modules</h4>
          <ul>
            <li>Managing Human Capital and Leadership</li>
            <li>Marketing Management</li>
            <li>Financial Analysis and Entrepreneurship</li>
            <li>Strategic Management</li>
            <li>Research Methodologies</li>
          </ul>
          <h4>Electives and Options</h4>
          <ul>
            <li>Corporate Finance</li>
            <li>International Business</li>
            <li>International Marketing Management</li>
          </ul>
          <h4>Major Project</h4>
          <ul>
            <li>Business Development Proposal</li>
            <li>Integrated Case Study</li>
            <li>Dissertation</li>
          </ul>
        </article>
      );
    } else if (lang === "es") {
      return (
        <article>
          <h1>Course Modules</h1>
          <h4>Módulos Centrales</h4>
          <ul>
            <li>Gestión de capital humano y liderazgo</li>
            <li>Gestión de marketing</li>
            <li>Análisis financiero y emprendimiento</li>
            <li>Gestión estratégica</li>
            <li>Metodologías de investigación</li>
          </ul>
          <h4>Módulos Electivos (2)</h4>
          <ul>
            <li>Finanzas corporativas</li>
            <li>Negocios Internacionales</li>
            <li>Gestión de marketing internacional</li>
          </ul>
          <h4>Opciones de proyecto de grado</h4>
          <ul>
            <li>Propuesta de desarrollo empresarial</li>
            <li>Estudio de caso integrado</li>
            <li>Disertación</li>
          </ul>
        </article>
      );
    } else if (lang === "ru") {
      return (
        <article>
          <h1>Модули курса</h1>
          <h4>Основные модули</h4>
          <ul>
            <li>Управление человеческим капиталом и лидерство</li>
            <li>Управление маркетингом</li>
            <li>Финансовый анализ и предпринимательство</li>
            <li>Стратегический менеджмент</li>
            <li>Методы исследования</li>
          </ul>
          <h4>Модули на выбор</h4>
          <ul>
            <li>Корпоративные финансы</li>
            <li>Международный бизнес</li>
            <li>Управление международным маркетингом</li>
          </ul>
          <h4>Дипломный проект</h4>
          <ul>
            <li>Предложение о развитии бизнеса</li>
            <li>Комплексное исследование бизнеса</li>
            <li>Диссертация</li>
          </ul>
        </article>
      );
    }
  };

  return (
    <>
      <h1 className="heading">MBA (Master of Business Administration)</h1>
      <section id="course">
        <div className="about">{About()}</div>
        <div className="modules">{Module()}</div>
      </section>
    </>
  );
};

export default course;
