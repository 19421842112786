import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import axios from "axios";

import LoaderGif from "../assets/img/loader.gif";

const Form = ({ lang }: { lang: string }) => {
  const [Content, setContent] = useState({
    full_name: "",
    email_address: "",
    phone_number: "",
    country_of_residence: "",
    submit: "",
    message: "",
  });

  useEffect(() => {
    if (lang === "en") {
      setContent({
        full_name: "Full name",
        email_address: "Email address",
        phone_number: "Phone number",
        country_of_residence: "Country of residence",
        submit: "Submit",
        message: "Thank you for your enquiry. We will contact you soon.",
      });
    } else if (lang === "es") {
      setContent({
        full_name: "Nombre(s) y apellido(s)",
        email_address: "Email",
        phone_number: "Teléfono",
        country_of_residence: "País de residencia",
        submit: "Enviar",
        message: "Thank you for your enquiry. We will contact you soon.",
      });
    } else if (lang === "ru") {
      setContent({
        full_name: "Полное имя",
        email_address: "E-mail",
        phone_number: "Номер телефона",
        country_of_residence: "Страна проживания",
        submit: "Отправить",
        message: "Спасибо за ваш запрос. Мы свяжемся с вами в ближайшее время.",
      });
    }
  }, [lang]);

  const [Lead, setLead] = useState({
    full_name: "",
    email_address: "",
    phone_number: "",
    country_of_residence: "",
  });

  const [Loading, setLoading] = useState(false);

  const HandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLead((lead) => ({ ...lead, [e.target.name]: e.target.value }));
  };

  const HandleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("full_name", Lead.full_name);
    data.append("email_address", Lead.email_address);
    data.append("phone_number", Lead.phone_number);
    data.append("country_of_residence", Lead.country_of_residence);
    data.append("course", "63");
    data.append("website", "4");
    data.append("to", "shawn.rakhimov@lsclondon.co.uk");

    axios
      .post("https://www.lsclondon.co.uk/api/lead.php", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status) {
          setLead({
            full_name: "",
            email_address: "",
            phone_number: "",
            country_of_residence: "",
          });
          alert(Content.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="fields">
      <form onSubmit={HandleSubmit}>
        <fieldset>
          <label>{Content.full_name}</label>
          <input type="text" placeholder={Content.full_name} name="full_name" value={Lead.full_name} onChange={HandleChange} required></input>
        </fieldset>
        <div className="x2">
          <fieldset>
            <label>{Content.email_address}</label>
            <input
              type="email"
              placeholder={Content.email_address}
              name="email_address"
              value={Lead.email_address}
              onChange={HandleChange}
              required
            ></input>
          </fieldset>
          <fieldset>
            <label>{Content.phone_number}</label>
            <input
              type="tel"
              placeholder={Content.phone_number}
              name="phone_number"
              value={Lead.phone_number}
              onChange={HandleChange}
              required
            ></input>
          </fieldset>
        </div>
        <fieldset>
          <label>{Content.country_of_residence}</label>
          <input
            type="text"
            placeholder={Content.country_of_residence}
            name="country_of_residence"
            value={Lead.country_of_residence}
            onChange={HandleChange}
            required
          ></input>
        </fieldset>
        <br />
        <br />
        <fieldset>
          <div className="x2">
            <div>
              <button type="submit">{Content.submit}</button>
            </div>
            <div>{Loading ? <img src={LoaderGif} alt="Loading" width="40"></img> : ""}</div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default Form;
