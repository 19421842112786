import LSCGroup from "../assets/img/lscgroup.svg";
import nchfeLogo from "../assets/img/ncfhe.svg";

import Facebook from "../assets/img/facebook.png";
import Instagram from "../assets/img/insta.png";
import Twitter from "../assets/img/twitter.png";
import Youtube from "../assets/img/utube.png";

const contact = ({ lang }: { lang: string }) => {
  const Content = () => {
    if (lang === "en") {
      return (
        <article>
          London School of Commerce Malta (LSC)
          <br />
          <strong>Address:</strong> Europa Centre, St. Anne Street, Floriana FRN9020, Malta
          <br />
          <strong>Telephone:</strong> +356 2122 6200
          <br />
          <strong>Email:</strong> <a href="mailto:info@lscmalta.edu.mt">info@lscmalta.edu.mt</a>
        </article>
      );
    } else if (lang === "es") {
      return (
        <article>
          London School of Commerce Malta (LSC)
          <br />
          <strong>Dirección:</strong> Europa Centre, St. Anne Street, Floriana FRN9020, Malta
          <br />
          <strong>Teléfono:</strong> +356 2122 6200
          <br />
          <strong>Email:</strong> <a href="mailto:info@lscmalta.edu.mt">info@lscmalta.edu.mt</a>
        </article>
      );
    }
  };

  return (
    <footer>
      <div>{Content()}</div>
      <div>
        <article>
          <img src={LSCGroup} alt="London School of Commerce Group of Colleges Logo"></img>
          <img src={nchfeLogo} alt="National Commission for Further and Higher Education"></img>
        </article>
      </div>
      <div>
        <article>
          <a href="https://www.facebook.com/LSCmalta" target="_blank" rel="noreferrer">
            <img src={Facebook} alt="Facebook"></img>
          </a>
          <a href="https://www.instagram.com/lscmalta/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="Instagram"></img>
          </a>
          <a href="https://twitter.com/lsc_malta" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="Twitter"></img>
          </a>
          <a href="https://www.youtube.com/channel/UCIYD7lXGSG9Nh79vK7lr2Tg" target="_blank" rel="noreferrer">
            <img src={Youtube} alt="Youtube"></img>
          </a>
        </article>
      </div>
    </footer>
  );
};

export default contact;
