import LscMaltaLogo from "../assets/img/lscmalta.svg";
import UosLogo from "../assets/img/university-of-suffolk.svg";

const Header = () => {
  return (
    <header id="header">
      <div>
        <img src={LscMaltaLogo} alt="London School of Commerce Malta Logo"></img>
      </div>
      <div></div>
      <div>
        <img src={UosLogo} alt="University of Suffolk Logo"></img>
      </div>
    </header>
  );
};

export default Header;
